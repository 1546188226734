import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ShopProvider from './context/shopContext';
import { ChakraProvider } from '@chakra-ui/react';

const rootElement = document.getElementById('root');
const root = ReactDOMClient.createRoot(rootElement)
root.render(

  <ChakraProvider>
    <ShopProvider>
      <App tab="homepage" />
    </ShopProvider>
  </ChakraProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
